.photo-library {
    padding: 40px;
    background-color: white !important;
    
    h1{
        font-family: 'Neo Sans Regular';        
        font-size: 30px;
    }
  
  .header{
    display: flex;
    align-items: center;
    justify-content: end;
    }


.btn-white {
    background-color: white !important;
    border: 1px solid #054437 !important;
    box-shadow: 0px 7px 10px 3px rgb(0 0 0 / 16%);
    border-radius: 10px !important;
    color: #054437 !important;
    padding: 10px 0;
    font-size: 14px;
    font-weight: bold !important;
    width: 150px;
    font-family: 'Neo Sans Regular';
  }
  
  .btn-white:hover {
      
      box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  }

  .lightgallery-img{
    position: absolute;
    width: 50%;
    z-index: 10;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .footer-text{
    position: absolute;
    right: 80px;
    bottom: 100px;
  }

  .arch{
    margin-top: -200px;
    width: 100%;
  }



  @media (max-width: 992px){
    .lightgallery-img{
        width: 70%;
    }
  
  }

  @media (max-width: 768px){

    padding: 20px;
    .title-div{
      width: 100%;
    }
    
    .lightgallery-img{

      width: 70%;
      top: 45%;
      left: 50%;

    }
    .header{
      flex-direction: column-reverse;
    }
    .btn-white{
      padding: 8px 0;
      font-size: 12px;
      width: 100px;
    }
    
    .logo-div{
      width: 100%;
    }
    
  }

  @media (max-width: 576px){

    .lightgallery-img{
      width: 80%;
    }

    .title-div{
      position: absolute;
      width: 50%;
      z-index: 10;
      top: 30%;
      left: 35%;
      transform: translate(-50%, -50%);
      cursor: pointer;
  }


  }

  @media (max-width: 480px){
    .title-div{
      top: 30%;
  }
  }

@media (max-width: 400px){
  .title-div{
    top: 32%;
}
}


@media (orientation: landscape) and (hover: none) and (pointer: coarse) {


  .lightgallery-img{
    position: static !important;
    transform: none;
    text-align: center;
}

  .arch{
    margin-top: 0 !important;
  }

}

}




.pl-logo {
  width: 250px;
}




.pl-footer-icons{

  margin-left: 20px;
  img{
    display: inline;
  }
  
  .credibility{
    width: 125px;
    border-right: 1px solid darkgray;
    padding-right: 20px;
  }
  
  .agility{
    width: 125px;
    margin-top: 5px;
    padding: 0 20px;
  }
  
  .differently{
    width: 120px;
    padding-left: 20px;
    border-left: 1px solid darkgray;
  }
}

@media (max-width: 992px){

  .pl-footer-icons{

    
    .credibility{
      width: 140px;
    }
    
    .agility{
      width: 130px;
    }
    
    .differently{
      width: 125px;
    }
  }

}

@media (max-width: 768px){
  
  .pl-footer-icons{
    margin-left: 0px;
    
    .credibility{
      width: 120px;
    }
    
    .agility{
      width: 120px;
    }
    
    .differently{
      width: 115px;
    }
  }

  .pl-logo{
    width: 180px;
  }



}




@media (max-width: 576px){


  .pl-footer-icons{

    
    .credibility{
      width: 100px;
      padding-right: 10px;
    }
    
    .agility{
      width: 90px;
      padding: 0 10px;
    }
    
    .differently{
      width: 85px;
      padding-left:10px;
    }
  }


}



@media (max-width: 480px) {
  .pl-logo{
    width: 150px;
  }
}
@font-face {
  font-family: "Neo Sans Regular";
  src: url("../fonts/NeoSansArabic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Neo Sans Medium";
  src: url("../fonts/Neo_Sans_Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Neo Sans Black";
  src: url("../fonts/NeoSansArabicBlack.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Neo Sans Bold";
  src: url("../fonts/NeoSansArabicBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Neo Sans Light";
  src: url("../fonts/NeoSansArabicLight.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "Neo Sans Ultra";
  src: url("../fonts/NeoSansArabicUltra.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

*{
  font-family:'Neo Sans Regular';
}

.wrapper {
  background-size: cover !important;
  background-position: center !important;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.home {
  padding: 40px;
  background-image: url("../images/home-bg.png");
  font-family: "Neo Sans Regular";
}

.agenda {
  padding: 40px;
  background: linear-gradient(rgba(255, 255, 255, 0.5),rgba(255, 255, 255, 0.5)), url("../images/background.jpg");
}


.main-content {
  flex: 1;
}

.logo {
  width: 350px;
}

.title {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: fit-content;
  text-align: center;
}

.btn-transparent {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px !important;
  color: #054437;
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold !important;
  width: 150px;
  font-family: 'Neo Sans Regular';
}

.btn-transparent:hover {
  color: #054437;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
}


.footer-text{
  margin-bottom: 70px;
}


.home-footer{
  display: block;
}

@media (max-width: 992px) {
  .btn-transparent {
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: white !important
  }

  .btn-transparent:hover {
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  }
  // .main-content {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  // }
}


@media (max-width: 768px) {

  .logo{
    width: 200px;
  }

  
  .wrapper {
    padding: 30px 20px;
  }

  .title {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 20px 10px;
    margin-top: 80px;
  }
  .btn-transparent{
    padding: 8px 0;
    font-size: 12px;
    width: 100px;
  }
  .footer-text{
    margin-bottom: 20px;
  }

  .home-footer{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    h2{
      margin-bottom: 0;
    }
  }
  
}


@media (max-width: 480px) {
  .logo{
    width: 180px;
  }

  .home, .agenda {
    padding: 20px 20px 70px 20px;
  }

  .agenda {
    padding: 20px;
  }

  .title {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 20px 5px;
    margin-top: 120px;
  }


  .btn-transparent {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 10px !important;
    padding: 8px 0;
    font-size: 12px;
    width: 100px;
  }

  .home-footer{
    justify-content: space-between;
    h2{
      
      font-size: 20px;
    }
  }

}


@media (max-width: 380px) {

  .home-footer{
    justify-content: space-between;
    h2{
      
      font-size: 18px;
    }
  }
}


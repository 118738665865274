
a{
    text-decoration: none !important;
}

.text-primary{
    color: #054437 !important;
}

.text-white{
    color: #fff !important;
}

.text-bold{
    font-weight: bold;
}

.cursor-pointer{
    cursor: pointer !important;
}
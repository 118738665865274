

.table-div {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    
    table {
      width: 80%;
      border-collapse: collapse;
      table-layout: auto;
      border-bottom: 1px solid #0C3931;
    }

    th {
      background: #0C3931;
      color: white;
      font-weight: bold;
      padding: 2px 10px 0 10px;
      font-size: 24px;
      font-family: 'Neo Sans Regular';
    }

    tr.break-row td{
        color: #82BD00;
        text-align: center;
        font-weight: bold !important;
        font-family: 'Neo Sans Regular' !important;
      }
    
    tr.break-row, tr.photo-row td{
        color: #82BD00;
        text-align: center;
        font-weight: bold !important;
        font-family: 'Neo Sans Regular' !important;
      }
    
  
    tr{
      color: #0C3931 ;
      &:nth-child(even) {
        background: #E9E9E2;
      }
      
      &:nth-child(odd) {
        background: #FFFFFE;
      }
    }
    
    
  
    td{
      padding: 5px 10px 0 10px;
      font-size: 18px;
      font-family: 'Neo Sans Light';
    }
  }


  .footer-icon {
    display: inline;
    margin-right: 30px;
  }

  .credibility{width: 185px;}
  
  .agility{width: 160px;}
  
  .differently{width: 160px;}
  
  @media (max-width: 1200px) {
    table {
      width: 100%;
    }
  
  }


@media (max-width: 992px) {

  .credibility{width: 140px;}
  
  .agility{width: 120px;}
  
  .differently{width: 120px;}
  


.table-div {
    margin-bottom: 20px;
    table{
        width: 100%;
    }
    
    th {
    padding: 0 8px;
    font-size: 20px;
    font-family: 'Neo Sans Light';
    }
    
  
    td{
      padding: 0 8px;
    }
  }


  }


@media (max-width: 768px) {
    .table-div {

     
        th {
          padding: 0 8px;
          font-size: 18px;
        }
        
      
        td{
          padding: 0 8px;
          font-size: 14px;
        }
      }
  }
  
 @media (max-width: 600px) {

  .footer-icon {
    margin-right: 10px;
  }
 }
  
 
@media (max-width: 480px) {
  .credibility{width: 100px;}
  
  .agility{width: 90px;}
  
  .differently{width: 90px;}
}
  

@media (max-width: 400px) {
  .credibility{width: 9.0px;}
  
  .agility{width: 80px;}
  
  .differently{width: 80px;}
}

@media (max-width: 300px) {

    .table-div {
        th {
          padding: 0 8px;
          font-size: 12px;
        }
        
      
        td{
          padding: 0 8px;
          font-size: 10px;
        }
      }
     

  }
  
  
  
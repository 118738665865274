.admin-login-wrapper{
    height: 100vh;
    background: linear-gradient(#0e3f33,#115d4a);
    
    *{
        font-family: 'Neo Sans Regular';
    }
}


.btn-login {
    background-color: white !important;
    border: 1px solid #054437 !important;
    box-shadow: 0px 7px 10px 3px rgb(0 0 0 / 16%);
    border-radius: 10px !important;
    color: #054437 !important;
    padding: 10px 0;
    font-size: 14px;
    font-weight: bold !important;
    width: 100px;
    font-family: 'Neo Sans Regular';
  }
  
  .btn-login:hover {
      box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  }


.admin-content{
    *{
        font-family: 'Neo Sans Regular';
    }
    
    .breadcrumb-item{
        background-color: #054437 !important;
        border-radius: 10px;
        padding: 20px 30px !important;
    }
}

.modal{
    
    *{
        font-family: 'Neo Sans Regular';
    }

    label{
        color: #054437;
    }


.admin-green-btn {
    background-color: #054437 !important;
    color: white !important;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 20px;
    border: 2px solid white;
    border-radius: 15px;
    transition: 0.2s;
}

.admin-green-btn:hover {
    border: 2px solid white;
}


.green-boreder{
    border: 1px solid #054437;
}

.admin-grey-btn {
    background-color: #6c757d !important;
    color: white !important;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 20px;
    border: 2px solid white;
    border-radius: 15px;
    transition: 0.2s;
}

.admin-grey-btn:hover {
    border: 2px solid white;
}

input{
    border: 1px solid #054437;
}

}